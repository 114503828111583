<script>
export default {
  props: {
    item: Object,
    size: {
        type: String,
        default: '50px'
    }
  },
  data() {
    return {      
    }
  },
  components: {
  },
  computed: {
    fileType() {
        return (item) => {
            if (!item.mime_type) return 'other';

            if (item.mime_type.startsWith('image/')) {
                return 'image';
            } else if (item.mime_type === 'application/pdf') {
                return 'pdf';
            } else if (
                item.mime_type === 'application/msword' ||
                item.mime_type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
            ) {
                return 'word';
            } else if (
                item.mime_type === 'application/vnd.ms-excel' ||
                item.mime_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ) {
                return 'excel';
            } else if (
                item.mime_type === 'application/vnd.ms-powerpoint' ||
                item.mime_type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
            ) {
                return 'powerpoint';
            } else {
                return 'other';
            }
        };
    },
  },
  methods: {
  }
}
</script>

<template>
    <img v-if="fileType(item) === 'image'" :src="item.url" :alt="item.alt">
    <i v-else :class="{
            'ri-file-pdf-line text-danger': fileType(item) === 'pdf',
            'ri-file-word-line text-primary': fileType(item) === 'word',
            'ri-file-excel-line text-success': fileType(item) === 'excel',
            'ri-file-ppt-line text-warning': fileType(item) === 'powerpoint',
            'ri-file-line text-secondary': fileType(item) === 'other'
        }" :style="{ fontSize: size }">
    </i>
</template>
  