var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.fileType(_vm.item) === 'image')?_c('img',{attrs:{"src":_vm.item.url,"alt":_vm.item.alt}}):_c('i',{class:{
        'ri-file-pdf-line text-danger': _vm.fileType(_vm.item) === 'pdf',
        'ri-file-word-line text-primary': _vm.fileType(_vm.item) === 'word',
        'ri-file-excel-line text-success': _vm.fileType(_vm.item) === 'excel',
        'ri-file-ppt-line text-warning': _vm.fileType(_vm.item) === 'powerpoint',
        'ri-file-line text-secondary': _vm.fileType(_vm.item) === 'other'
    },style:({ fontSize: _vm.size })})
}
var staticRenderFns = []

export { render, staticRenderFns }